/*
 * @Descripttion: 登录API
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-08-30 16:43:33
 * @LastEditTime: 2021-09-01 11:31:32
 */
const url = 'index/Login/'
import {
    $get,
    $post
} from "@/utils" 

/**
 * 验证码
 *  @param {array} params 请求参数
 */
 
  //获取注册验证码
export let getCaptcha = function (params) {
    return $get(url + 'captcha',params)
}


// 登录
  export let login = function (params) {
    return $post(url + 'login',params)
}
// 退出登录
export let logout = function (params) {
  return $post(url + 'logout',params)
}