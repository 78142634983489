<!--
 * @Descripttion: 出售帐号列表
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-08-12 15:02:57 
 * @LastEditTime: 2021-11-25 17:47:35
-->
<template>

  <div v-wechat-title="titleName"
       ref='scroll'>

    <div class="breadcrumb">

      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>区服</el-breadcrumb-item>
        <el-breadcrumb-item>{{titleName}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="padding:0.5rem">
      <el-button @click="returnPage"
                 size="small"
                 icon="el-icon-arrow-left">上一页</el-button> <br class="pcHidden" />

      <el-select class="select-list-index"
                 size="mini"
                 v-model="query.zz_cid"
                 @change="ZhongZhuChangeQuery"
                 placeholder="种族">
        <el-option label="全部"
                   value="0"></el-option>
        <el-option v-for="item in zhongzu"
                   :key="item.category_id"
                   :label="item.category_name"
                   :value="item.category_id">
        </el-option>
      </el-select>
      <el-select class="select-list-index"
                 size="mini"
                 v-model="query.zy_cid"
                 @change="ZhiYeChangeQuery"
                 placeholder="职业">
        <el-option label="全部"
                   value="0"></el-option>
        <el-option v-for="item in zhiye"
                   :key="item.category_id"
                   :label="item.category_name"
                   :value="item.category_id">
        </el-option>
      </el-select>
      <el-select labelInValue
                 class="select-list-index"
                 size="mini"
                 value-key="id"
                 v-model="query.sortStr"
                 @change="SortChangeQuery"
                 placeholder="排序">
        <el-option label="价格升序"
                   :value="{id:'1',sort_field:'price',sort_type:'asc'}"></el-option>
        <el-option label="价格降序"
                   :value="{id:'2',sort_field:'price',sort_type:'desc'}"></el-option>
        <el-option label="时间升序"
                   :value="{id:'3',sort_field:'update_time',sort_type:'asc'}"></el-option>
        <el-option label="时间降序"
                   :value="{id:'4',sort_field:'update_time',sort_type:'desc'}"></el-option>
      </el-select>
      <el-input style="width: 180px;padding: 0px 15px 9px 2px"
                class="filter-item"
                size="small"
                v-model="query.title"
                v-on:input="TitleChangeQuery"
                placeholder="请输入编号ID或主装备" />
      <el-button v-if="false"
                 class="filter-item"
                 size="medium"
                 type="primary"
                 @click="search()">查询</el-button>
      <el-button v-if="false"
                 class="filter-item"
                 size="medium"
                 @click="refresh()">刷新</el-button>

      <div style="
    padding: 5px 0;
    margin: 2px 5px;
    font-size: 12px;
    color: orange;
    border: 1px solid hsla(0,0%,100%,.4);
    background: hsla(0,0%,100%,.4);
    border-radius: 2px;
    text-align: center;
">可注册自己上架，觉得账号显示太乱，可以选择职业排序，筛选</div>
      <div class="flex">

        <div  :class="item.isClick===true?' gr_list active':' gr_list'"
             v-for="(item,index) in GameRoleList"
             :key="index"
               :style="item.is_hot===1?'border: 1px solid '+item.color+';box-shadow: inset 0 0 10px 0 '+item.color+';color:'+item.color:'color: #a9a9a9;'"
             >
          <div :id="`anchor${item.game_role_id}`"
               @click="goto(item)"
               class="gr_list_div"
             >
            <div class="divTitle">
              <div class="divLeft"
                   :style="item.is_hot===1?'color:'+item.color:''">
                <span class="spanLeft">{{item.game_role_id}}-</span>
                <span class="spanLeft">{{item.zhiye}}-</span>
                <span class="spanTitle">{{item.title}}
                  <!-- <span style="color:red"
                        v-if="item.imgs==='多图'">【多图】</span> -->
                  <img v-if="item.ZH_Type=='QQ账号'"
                       :src="wegameImg"
                      />
                  <span style="width: 18px;"
                        v-if="item.ZH_Type=='盛趣账号'"> &nbsp; </span>
                </span>
              </div>
              <div class="divRight">

                <!-- <img v-if="item.ZH_Type=='盛趣账号'"
                     :src="shengquImg"
                     width="18"
                     style="margin-left: 0.4rem" /> -->
                <span class="spanDate">

                  <i class="mHidden">更新：</i>{{item.update_time}}</span>

                <span class="spanPrice">￥{{item.price|money}}</span>

              </div>
            </div>

            <div class="div_contacts">{{item.description}}</div>

          </div>

        </div>

      </div>
      <div ref='backtop'></div>
    </div>

  </div>

</template>

<script>
import { getGameRoleList, getCateGory, getCateGoryInfo } from "@/api";
import "@/styles/GameRole.css";
export default {
  name: "DbgamewebGamerolelist",
  data() {
    return {
      titleName: "",
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      query: {
        //查询分页
        page: 1,
        limit: 24,
        zz_cid: "",
        zy_cid: "",
        sort_field: "",
        sort_type: "",
        sortID: "",
      },
      getScrollTop: 0,
      loding: true,
      zhiye: [],
      zhongzu: [],
      GameRoleList: [],
      shengquImg: require("@/assets/shengqu.png"),
      wegameImg: require("@/assets/wegame.png"),
    };
  },
  created() {
    this.getCateGoryInfo();
  },
  mounted() {

  this.goToAnchor();


    //console.log("2.", this.GameRoleList);

    //监听如果页面发生滚动时
    window.addEventListener("scroll", this.handleScroll, true);

    this.getZhongZu();
  },

  methods: {
    load() {
      // console.log("1111111",1);
    },
    //页面滑到底部需要调用的方法
    handleScroll(event) {
      let that = this;
      //下面这部分兼容手机端和PC端
      let thisHeight = window.screen.height; //设备高度
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop; //拉动的距离
      //let scrollHeight = this.$el.clientHeight-thisHeight - this.scroll; //页面高度-拉动的距离

      let scrollBottom =
        event.target.scrollHeight -
        event.target.scrollTop -
        event.target.clientHeight;
      if (!scrollBottom) {
        scrollBottom = 0;
      }

      this.getScrollTop = this.scroll || event.target.scrollTop;
      //  console.log("scrollBottom",scrollBottom);
      //   console.log("Bottom",this.$el.clientHeight- this.scroll );
      //  console.log("设备高度",thisHeight);
      // console.log('拉动的距离',this.scroll);
      //   console.log("页面高度1", this.$el.clientHeight);

      //if (scrollHeight - thisHeight <= 50) {

      if (
        (scrollBottom == 0 &&
          this.$el.clientHeight - this.scroll < thisHeight) ||
        (that.scroll == 0 && scrollBottom < 50)
      ) {
        //距离底部将近50的时候 可以加载
        if (this.loding) {
          //console.log('可以加载了')
          this.query.page += 1;
          this.getAddGameRoleList();
        }
      }
    },
    refresh() {
      this.query = this.$options.data().query;
      this.getGameRoleList();
    },

    search() {
      this.query.page = 1;
      this.getGameRoleList();
    },
    returnPage() {
      this.$router.go(-1);
    },
    goto(item) {
      this.GameRoleList.map((lItem) => {
        lItem.isClick = false;
      });
      item.isClick = true;
      let data = {
        wrapScrollTop: this.getScrollTop,
        wrapScrollId: item.game_role_id,
        query: {
          qf_cid: this.$route.params.id,
          zz_cid: this.query.zz_cid,
          zy_cid: this.query.zy_cid,
          sort_field: this.query.sort_field,
          sort_type: this.query.sort_type,
          sortID: this.query.sortID,
          page: this.query.page,
        },

        //  hasMore: this.hasMore,
        GameRoleList: this.GameRoleList,
      };

      sessionStorage.setItem("hashData", JSON.stringify(data));

      this.$router.push(
        "/GameRoleInfo/" + this.$route.params.id + "/" + item.game_role_id
      );
    },
    // 详情跳转过来的记住位置
    //根据页面hash值，定位到指定列表项
    goToAnchor() {
      this.isToDetail = false;
      let data = JSON.parse(sessionStorage.getItem("hashData") || "{}");
  //console.log(sessionStorage.getItem("hashData"))
      if (sessionStorage.getItem("hashData")) {
        // console.log('data.form', data.form)
        this.query = JSON.parse(JSON.stringify(data.query));
        this.query.zz_cid = data.query.zz_cid;
        this.query.zy_cid = data.query.zy_cid;
        this.query.sort_field = data.query.sort_field;
        this.query.sort_type = data.query.sort_type;
        this.query.sortID = data.query.sortID;
        this.query.page = data.query.page || 1;
        // console.log(this.form.careerCode, '===', data.form.careerCode)
      }

      this.GameRoleList = data.GameRoleList || [];
      // console.log(this.$route.hash, "===", data.wrapScrollTop);
      //console.log("this.GameRoleList.length", this.GameRoleList.length);
      if (
        this.GameRoleList.length != 0 &&
        this.query.qf_cid == this.$route.params.id
      ) {
        //this.$route.hash =>不能用这个判断，浏览器返回键没有带这个参数
        this.$nextTick(() => {
          let anchor = this.$el.querySelector(`#anchor${data.wrapScrollId}`);
          //console.log($('#app').scrollTop(), $('body').scrollTop())
          if (anchor) {
            let offsetY = data.wrapScrollTop; //anchor.offsetTop;
            window.scrollTo({
              top: offsetY,
            });
            //console.log("xxx");
            //console.log('anchor', anchor, 'offsetY', offsetY, $('#app'))
            //  $("#app") && $("#app")[0].scrollTo(0, offsetY); //用户不要这种定位，只要把列表通通加载出来就可以了
          }
          sessionStorage.removeItem("hashData"); //用完就删
        });
      } else {
        //console.log("执行");
        sessionStorage.removeItem("hashData"); //用完就删
        this.query.page = 1;
        this.query.limit = 24;

        this.getGameRoleList();
      }
    },
    //获获取职业分类列表
    getZhongZu() {
      let params = {
        pid: 24,
        sort_field: "category_id",
        sort_type: "asc",
      };
      //24是职业的ID
      getCateGory(params).then((res) => {
        //res.data.clist.pop(); //移除最后一个数组，全职业
        res.data.clist.shift();
        this.zhongzu = res.data.clist;
      });
    },
    getZhiYe() {
      // console.log(this.query);
      let params = {
        pid: this.query.zz_cid,
        sort_field: "category_id",
        sort_type: "asc",
      };
      //24是职业的ID
      getCateGory(params).then((res) => {
        this.zhiye = res.data.clist;
      });
    },
    //获取帐号列表
    getGameRoleList() {
      //console.log("默认", this.query.limit);
      this.loding = false;
      let params = {
        title: this.query.title,
        qf_cid: this.$route.params.id,
        zy_cid: this.query.zy_cid,
        sort_field: this.query.sort_field,
        sort_type: this.query.sort_type,
        page: this.query.page,
        limit: this.query.limit,
        zz_cid: this.query.zz_cid,
        status: 1,
      };
    
      getGameRoleList(params).then((res) => {
      if (res.data.list.length != 0) {
          
          this.GameRoleList = res.data.list;
          // console.log( this.GameRoleList)
          this.loding = true;
        } else {

          // console.log('没数据了')
          this.GameRoleList = res.data.list;
        }
      }) .catch((error) => {

        alert(error)
      });
      
    },
    getAddGameRoleList() {
      this.loding = false;
      let params = {
        title: this.query.title,
        qf_cid: this.$route.params.id,
        zy_cid: this.query.zy_cid,
        sort_field: this.query.sort_field,
        sort_type: this.query.sort_type,
        zz_cid: this.query.zz_cid,
        page: this.query.page,
        limit: this.query.limit,
        status: 1,
      };

      getGameRoleList(params).then((res) => {
        //console.log(res.data.list);
        if (res.data.list.length != 0) {
          this.GameRoleList = this.GameRoleList.concat(res.data.list);
          // console.log( this.GameRoleList)
          this.loding = true;
        } else {
          // console.log('没数据了')
        }
      });
    },
    //获取分类信息
    getCateGoryInfo() {
      let params = {
        pid: this.$route.params.id,
      };
      getCateGoryInfo(params).then((res) => {
        this.titleName = res.data.category_name;
      });
    },
    ZhongZhuChangeQuery(value) {
      if (value == 0) {
        this.zhiye = "";
        this.query.zy_cid = "";
      } else {
        this.query.zz_cid = value;
        this.query.zy_cid = "";
        this.getZhiYe();
      }
      this.query.page = 1;
      this.getGameRoleList();
    },
    ZhiYeChangeQuery(value) {
      this.query.zy_cid = value;
      this.query.page = 1;
      this.getGameRoleList();
    },
    //查询条件Change
    SortChangeQuery(e) {
      this.query.sort_field = e.sort_field;
      this.query.sort_type = e.sort_type;
      this.query.page = 1;
      this.getGameRoleList();
    },
    TitleChangeQuery(e) {
      this.query.title = e;
      this.query.page = 1;
      this.getGameRoleList();
    },
  },
  filters: {
    //钩子，过滤器
    money(value) {
      if (!value) return "0.00";
      var intPart = Number(value).toFixed(0); //获取整数部分
      var intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); //将整数部分逢三一断
      var floatPart = ""; //.00预定义小数部分
      var value2Array = value.split(".");
      //=2表示数据有小数位
      if (value2Array.length == 2) {
        floatPart = value2Array[1].toString(); //拿到小数部分
        if (floatPart.length == 1) {
          //补0,
          return intPartFormat; // + "." + floatPart + "0";
        } else {
          return intPartFormat; // + "." + floatPart;
        }
      } else {
        return intPartFormat; // + floatPart;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  border: solid 1px #dbf21e;
  box-shadow: inset 0 0 10px 0 rgba(255, 255, 0, 0.8);
}
.filter-item {
  padding: 9px 15px;
}

.el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
}
</style>