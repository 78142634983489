/*
 * @Descripttion: 注释名称
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-08-09 21:22:55
 * @LastEditTime: 2021-10-04 02:09:15
 */
import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
import store from './store'
import Mint from 'mint-ui';
import 'mint-ui/lib/style.css'
import '@/styles/index.css' //全局CSS
import '@/styles/mindex.css' //全局手机CSS
import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)
Vue.use(Mint);
Vue.use(ElementUI)

Vue.config.productionTip = false

// 轮播图调用
import VueAwesomeSwiper from 'vue-awesome-swiper'

import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper);



/**
 * 全部变量和通用
 */

// import Common from '@/components/Common'
// Vue.prototype.Common = Common;



/**
 * 百度UEditor编辑器引用
 */

 import '../public/static/ueditor/ueditor.config.js'
 import '../public/static/ueditor/ueditor.all.js'
 import '../public/static/ueditor/lang/zh-cn/zh-cn.js'
 import '../public/static/ueditor/ueditor.parse.js'









// this.$router.push 重复跳转报错问题
const originaPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originaPush.call(this, location).catch(err => err)
}



new Vue({
  store,
  render: h => h(App),
  router

}).$mount('#app')