<!--
 * @Descripttion: 文章详细页
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-08-10 01:39:29
 * @LastEditTime: 2021-09-01 12:31:15
-->
<template>

  <div class="content3">
    <div  class="breadcrumb">

      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{titleName}}</el-breadcrumb-item>
 
      </el-breadcrumb>
    </div>
    <div  class="content2" style="">
      <dl v-html="content">
      </dl>
    </div>
  </div>
</template>

<script>
import { getArticleInfo } from "@/api";
export default {
  name: "DbgamewebArticleinfo",

  data() {
    return {
      content: "",
      titleName:""
    };
  },
  watch: {
    $route() {
      this.getArticleInfo();
    },
  },
  mounted() {
    this.getArticleInfo();
  },
  methods: {
    getArticleInfo() {
      getArticleInfo(this.$route.params.id).then((res) => {
        this.content = res.data.content;
        this.titleName=res.data.title
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>