<!--
 * @Descripttion: 主要VUE
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-08-09 21:22:55
 * @LastEditTime: 2021-10-05 15:53:35
-->
<template>

  <div id="app" >

    <div >

      <div  class="login-filter">

        <div class="content "
             :style="'width:'+contentWidth+'px;'">

          <router-view :bannerWidth="bannerWidth"></router-view>
          <el-backtop></el-backtop>
        </div>

      </div>
    

    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      bannerWidth: 505,
      contentWidth: 1000,

      fixStyle: "",
      fixStyle2: "",
    };
  },
  mounted() {
    // //主要是检测窗口缩放视频也同步大小
    // window.onresize = () => {
    //   const windowWidth = document.body.clientWidth;
    //   const windowHeight = document.body.clientHeight;
    //   const windowAspectRatio = windowHeight / windowWidth;

    //   //  const pcwindowWidth=innerWidth
    //   let videoWidth;
    //   let videoHeight;
    //   let contentWidth;
    //   if (windowAspectRatio < 0.5625) {
    //     videoWidth = windowWidth;
    //     //videoHeight = windowHeight//videoWidth * 0.5625;
    //     contentWidth = windowWidth * 0.8;
    //     if (contentWidth >= 1280) {
    //       contentWidth = 1280;
    //     }
    //     this.contentWidth = contentWidth;

    //     if (windowWidth > 1200 && windowWidth <= 1480) {
    //       this.bannerWidth = contentWidth - 280;
    //     } else if (windowWidth > 768 && windowWidth <= 1200) {
    //       this.bannerWidth = contentWidth;
    //     } else if (windowWidth <= 768) {
    //       this.bannerWidth = windowWidth;
    //     } else {
    //       this.bannerWidth = contentWidth - 215 - 280;
    //     }
    //     //  console.log(11111);
    //     // console.log("windowWidth", windowWidth);
    //     // console.log("contentWidth", contentWidth);
    //     //           console.log("windowHeight",windowHeight);

    //     //        console.log(" document.body.clientWidth", document.body.clientWidth);
    //     //  console.log("videoWidth",videoWidth);
    //     //  console.log("videoHeight",videoHeight);
    //     //   console.log(" document.documentElement", document.documentElement.clientHeight);
    //     //  console.log("window.screen.height",window.screen.height);
    //     //  console.log("windowWidth",windowWidth);
    //     //  console.log("myvideo",document.getElementById('myvideo').clientWidth);

    //     let strWidth = "auto";
    //     let margin_left = "margin-left";
    //     if (document.documentElement.clientWidth >= 2060) {
    //       strWidth = document.documentElement.clientWidth + "px";
    //     } else {
    //       margin_left = (windowWidth - 2060) / 2 + "px";
    //     }
    //     this.fixStyle = {
    //       // height: windowHeight + "px", // windowWidth * 0.5625 + "px",
    //       // width: windowWidth + "px",

    //       height: document.documentElement.clientHeight + "px",

    //       width: strWidth,
    //       position: "fixed",
    //       "margin-bottom": (windowHeight - videoHeight) / 2 + "px",
    //       "margin-left": margin_left,
    //       background: "rgb(193 197 222)",
    //     };
    //     this.fixStyle2 = {
    //       width: windowWidth + "px",
    //       "margin-bottom": (windowHeight - videoHeight) / 2 + "px",
    //       "margin-left": "initial",
    //     };
    //   } else {
    //     videoHeight = windowHeight;
    //     videoWidth = videoHeight / 0.5625;
    //     contentWidth = windowWidth * 0.8;
    //     if (contentWidth >= 1280) {
    //       contentWidth = 1280;
    //     }
    //     this.contentWidth = contentWidth;
    //     if (windowWidth > 1200 && windowWidth <= 1480) {
    //       this.bannerWidth = contentWidth - 280;
    //     } else if (windowWidth > 768 && windowWidth <= 1200) {
    //       this.bannerWidth = contentWidth;
    //     } else if (windowWidth <= 768) {
    //       this.bannerWidth = windowWidth;
    //     } else {
    //       this.bannerWidth = contentWidth - 215 - 280;
    //     }
    //     //   console.log(22);
    //     // console.log("windowWidth", windowWidth);
    //     //  console.log("contentWidth", contentWidth);
    //     //  console.log("pcwindowWidth",pcwindowWidth);
    //     //     console.log(contentWidth);
    //     console.log("windowHeight", windowHeight);

    //     console.log(" document.body.clientWidth", document.body.clientWidth);
    //     console.log("videoWidth", videoWidth);
    //     console.log("windowWidth", windowWidth);

    //     this.fixStyle = {
    //       // height: windowHeight + "px",
    //       // width: windowHeight / 0.5625 + "px",
    //       height: window.screen.height,
    //       width: windowWidth,
    //       position: "fixed",
    //       "margin-left": (windowWidth - videoWidth) / 2 + "px",
    //       "margin-bottom": "initial",
    //     };
    //     this.fixStyle2 = {
    //       width: windowHeight / 0.5625 + "px",
    //       "margin-left": (windowWidth - videoWidth) / 2 + "px",
    //       "margin-bottom": "initial",
    //     };
    //   }
    // };
    // window.onresize();
  },
  methods: {
    decode(str) {
      return str.replace(/\\x(\w{2})/g, function (_, $1) {
        return String.fromCharCode(parseInt($1, 16));
      });
    },
  },
  created() {},
};


window.onload = function() {
  document.addEventListener('touchstart', function(event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function(event) {
    event.preventDefault()
  })
}
</script>

<style>
.login-homepage-hero-module,
.login-video-container {
  position: unset;
  height: auto;
  overflow: hidden;
}

.login-video-container .login-poster img,
.login-video-container video {
  z-index: 0;
  position: absolute;
}

.login-video-container .login-filter {
  z-index: 1;
  position: absolute;
  /* background: rgba(0, 0, 0, 0.4); */
}
/* .login-homepage-hero-module, .login-video-container {
    position: unset;
    height: auto;
} */
</style>
