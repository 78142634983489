/*
 * @Descripttion: 路由器页面
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-08-10 02:01:59
 * @LastEditTime: 2021-10-09 15:39:22
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import GameRoleList from '@/views/GameRole/GameRoleList'
import ArticleInfo from'@/views/Article/ArticleInfo'


Vue.use(VueRouter)
const router = new VueRouter({
    routes: [{
            path: '/',
            props: true,
            meta: {
                title: '主体'
            },
            component: () => import('@/views/Main'),
            children:[
                 {
                    path: '',
                    props: true,
                    meta: {
                        title: '首页'
                    },
                    component:() => import('@/views/Index'),// () => import('@/views/GameRole/GameRoleList')
                },
                {
                    path: '/GameRoleList/:id',
                    props: true,
                    meta: {
                        title: '出售帐号列表'
                    },
                    component:GameRoleList// () => import('@/views/GameRole/GameRoleList')
                },
                
                {
                    path: '/GameRoleLists',
                    props: true,
                    meta: {
                        title: '出售帐号列表'
                    },
                    component:() => import('@/views/GameRole/GameRoleLists')
                },
                {
                    path: '/Equips',
                    props: true,
                    meta: {
                        title: '出售帐号列表'
                    },
                    component:() => import('@/views/Equip/Equips')
                },
                {
                    path: '/GameRoleInfo/:pid/:game_role_id',
                    props: true,
                    meta: {
                        title: '帐号信息'
                    },
                    component: () => import('@/views/GameRole/GameRoleInfo')
                },
                {
                    path: '/articleinfo/:id',
                    props: true,
                    meta: {
                        title: '内容页面'
                    },
                    component:ArticleInfo// () => import('@/views/Article/ArticleInfo')
                },
                {
                    path:'/SoldOut',
                    props:true,
                    meta:{
                        title:'已出售列秒'
                    },
                    component:()=>import('@/views/GameRole/SoldOut')
                },
                {
                    path: '/SoldOutGameRoleInfo/:game_role_id',
                    props: true,
                    meta: {
                        title: '已出售帐号信息'
                    },
                    component: () => import('@/views/GameRole/SoldOutGameRoleInfo')
                },
                {
                    path: '/member/person',
                    props: true,
                    meta: {
                        title: '个人中心'
                    },
                    component: () => import('@/views/Member/Person'),
                    children:[
                        {
                            path: '/member/person/memberinfo',
                            props: true,
                            meta: {
                                title: '个人信息'
                            },
                            component: () => import('@/views/Member/MemberInfo')
                        },
                        {
                            path: '/member/person/membergamerole',
                            props: true,
                            meta: {
                                title: '我要卖帐号'
                            },
                            component: () => import('@/views/Member/MemberGameRole')
                        },
                        {
                            path: '/member/person/memberequip',
                            props: true,
                            meta: {
                                title: '我要卖装备'
                            },
                            component: () => import('@/views/Member/MemberEquip')
                        },
                        {
                            path: '/member/person/memberfavorite',
                            props: true,
                            meta: {
                                title: '收藏夹'
                            },
                            component: () => import('@/views/Member/MemberFavorite')
                        }
                    ]


                },
                {
                    path: '/Equip',
                    props: true,
                    meta: {
                        title: '出售装备列表'
                    },
                    component: () => import('@/views/Equip/Equip')
                },
            ],
           
        },
        {
            path: '/login',
            props: true,
            meta: {
                title: '登录注册'
            },
            component: () => import('@/views/Login/Login')
        },
        {
            path: '/forgetpwd',
            props: true,
            meta: {
                title: '找回密码'
            },
            component: () => import('@/views/Login/ForgetPwd')
        },
        {
            path: '/register',
            props: true,
            meta: {
                title: '注册'
            },
            component: () => import('@/views/Login/Register')
        },
        {
            path: "*",
            meta: {
                title: '未找到页面'
            },
            component: () => import('@/views/error/404')

        }
    ],
    scrollBehavior(to,from,savedPosition){
        if(savedPosition){
        return savedPosition;
        }else{
        return {x:0,y:0}
        }
        },
         //在页面离开时记录滚动位置
beforeRouteLeave (to, from, next) {
    this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    next()
   },
  
  //进入该页面时，用之前保存的滚动位置赋值
  beforeRouteEnter (to, from, next) {
    next(vm => {
     document.body.scrollTop = vm.scrollTop
    })
   },
});
//跳转前
router.beforeEach((to, from, next) => {
    next()
})
//完成跳转后
router.afterEach((to) => {
    document.title = to.meta.title
});
export default router