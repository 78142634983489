/*
 * @Descripttion: 工具
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-08-10 02:38:05
 * @LastEditTime: 2021-10-12 19:08:54
 */
import axios from 'axios'
import qs from 'qs'
import {
  BASEURL
} from '@/config'
import {
  Message,
  MessageBox
} from 'element-ui'
import store from '@/store'
import { getMemberToken } from '../store/cookies/user'
export let $get = (url, params) => {
  return axios.get(BASEURL + url, {
    params: params
  })
}

export let $post = (url, params) => {

  return axios.post(BASEURL + url, qs.stringify(params))
}


// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 发送请求之前
    if (store.state.user.memberToken) {
      // 让每个请求头部带上MemberToken
      config.headers['MemberToken'] = getMemberToken()
    }
    return config
  },
  error => {
    // 请求错误
    return Promise.reject(error)
  }
)

// 响应拦截器
axios.interceptors.response.use( 
  /**
   * 通过接口返回码确定返回状态
   * 还可以通过HTTP状态代码来判断请求状态
   */
  response => {
    // 响应数据
    const res = response.data

    // 返回码200：成功
    if (res.code === 200) {
      //console.log(res)
      return res
    } else {
      // 返回码401：AdminToken无效
      if (res.code === 401) {

        MessageBox.confirm(res.msg, '提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

        }).catch(() => {})
      } else {
        Message({
          // showClose: true,
          message: res.msg || 'Server error',
          type: 'error',
          duration: 1300
        })
      }
      return Promise.reject(new Error(res.msg || 'Server error'))
    }
  },
  error => {
    // 响应错误

    const res = error.response.data
    if (res.code === 401) {
      MessageBox.confirm(res.message, '提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

      }).catch(() => {})
    } else {
      Message({
        // showClose: true,
        message: res.message || error.message,
        type: 'error',
        duration: 1300
      })
    }
    return Promise.reject(error)
  }
)