/*
 * @Descripttion: user仓库模块
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-08-31 16:27:49
 * @LastEditTime: 2021-10-09 12:54:32
 */
import {
    login,logout
} from "@/api/login"

import {
    setMemberToken,
    getMemberToken,
    setMemberInfo,
    getMemberInfo,
    delMemberToken,
    delMemberInfo
} from "../cookies/user"

const state = {
    memberToken: getMemberToken(),
    memberInfo: getMemberInfo(),
}

const mutations = {
    SET_MEMBER_TOKEN: (state, memberToken) => {
        state.memberToken = memberToken
    },
    SET_MEMBER_INFO: (state, memberInfo) => {
        state.memberInfo = memberInfo
    },
}


const actions = {
    //登录
    login({
        commit
    }, userInfo) {
    
        const {
            username,
            password,
            captcha_id,
            captcha_code,
            RememberLogin            
        } = userInfo
        const returnData=new Promise((resolve, reject) => {
            login({
                username: username,
                password: password,
                captcha_id: captcha_id,
                captcha_code: captcha_code
            }).then(res => {

                const {
                    data
                } = res
                // console.log('成功', data)
                //console.log('成功',  data.member_token)
                commit('SET_MEMBER_INFO', JSON.stringify(res.data))
                commit('SET_MEMBER_TOKEN', data.member_token)
                
                setMemberToken(data.member_token,RememberLogin)
                setMemberInfo(data,RememberLogin)
            
                resolve()
            }).catch(err => {
                reject(err)
            })
        })
        //console.log(returnData)
        return  returnData
    },
      // 退出
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout().then(() => {
        commit('SET_MEMBER_TOKEN', '')
        commit('SET_MEMBER_INFO', '')
        delMemberToken()
        delMemberInfo()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
}
export default {
    namespaced: true,
    mutations,
    state,
    actions
}