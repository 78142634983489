/*
 * @Descripttion: 首页接口
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-08-10 02:45:42
 * @LastEditTime: 2021-10-12 16:24:59
 */
import {
    $get
} from "@/utils";
import {
    $post
} from "../utils";

//获取首页菜单栏
export let getMenu = function () {
    return $get("/index/cms.Content/list", {
        page: 1,
        limit: 10,
        sort_field: "content_id",
        sort_type: "asc"
    });
}
//获取文章内容
export let getArticleInfo = function (id) {
    return $get('/index/cms.Content/info', {
        content_id: id
    })
}
//获取分类
export let getCateGory = function (params) {
    return $get('/index/GameRole/category', params)
}
//获取分类信息
export let getCateGoryInfo = function (params) {
    return $get('/index/GameRole/category_info', params)
}
//帐号信息 添加访问次数
export let getAddHit = function (params) {
    return $post('/index/GameRole/addHit',params)
}
//根据区服id获取出售帐号列表
export let getGameRoleList = function (params) {
 
    return $post('index/GameRole/list',params)
}

//根据区服id获取出售帐号信息
export let getGameRoleInfo = function (params) {
    return $post('/index/GameRole/info',params)
}
