/*
 * @Descripttion: User cookie 存取操作
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-08-31 16:50:11
 * @LastEditTime: 2021-10-09 12:55:20
 */
import cookies from 'js-cookie'

const MemberTokenKey='MemberToken'
export function setMemberToken(MemberToken,RememberLogin){
    if(RememberLogin){
        return cookies.set(MemberTokenKey,MemberToken,{expires:30})
    }else{
        return cookies.set(MemberTokenKey,MemberToken)
    }
    
}
export function getMemberToken(){
    return cookies.get(MemberTokenKey)
}
export function delMemberToken(){
    return cookies.remove(MemberTokenKey)
}
export function setMemberInfo(MemberInfo,RememberLogin){
// console.log(RememberLogin);
    if(RememberLogin){
        return cookies.set('MemberInfo',JSON.stringify(MemberInfo),{expires:30})
    }else{
        return cookies.set('MemberInfo',JSON.stringify(MemberInfo))
    }
      
}
export function getMemberInfo(){
    //console.log(cookies.get('MemberInfo'));
    return cookies.get('MemberInfo')
}
export function delMemberInfo(){
    return cookies.remove('MemberInfo')
}